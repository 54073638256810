<template>
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="trade">
    <el-form-item label="证券代码">
      <CodeSearch v-show="!megaMode" :showDefault="false" v-model="ruleForm.name" :multiple="false" :codeType="codeType" :codeList="codeSList" @codeSelectChane="codeChange" />
    </el-form-item>
    <el-form-item label="报价方式" prop="wayTrade">
      <el-select v-model="ruleForm.wayTrade" placeholder="请选择">
        <el-option label="盘后定价" value="shanghai"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="开平" prop="resource" v-if="$route.params.id==='3'">
      <el-radio-group v-model="ruleForm.resource">
        <el-radio :label="0">开仓</el-radio>
        <el-radio :label="1">平仓</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="交易价格" prop="price">
      <el-input-number :min="0" v-model="ruleForm.price" disabled controls-position="right" class="trade-item-right"></el-input-number>
    </el-form-item>
    <!--    <el-form-item label="可用资金" prop="usable">-->
    <!--      <el-input-number v-model="ruleForm.usable" controls-position="right" class="trade-item-right"></el-input-number>-->
    <!--    </el-form-item>-->
    <el-form-item label="交易数量" prop="number">
      <el-input-number v-model="ruleForm.number" @blur="blurNumber" controls-position="right" class="trade-item-right"></el-input-number>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm',0)" :disabled="buyDisabled">买入下单</el-button>
      <el-button type="primary" @click="submitForm('ruleForm',1)" :disabled="buyDisabled">卖出下单</el-button>
      <el-button @click="resetForm('ruleForm')">重填</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { findFunds, placeAnOrder } from "./detailsApi";
import { ElMessage } from "element-plus";
import CodeSearch from '@/components/CodeSearch';
import { checkAuditTime } from "../../../trade-manage/tradeMethods";

export default {
  props: {
    price: {
      type: String,
      default: ''
    },
    codeList: {
      type: Array,
      default: []
    },
    megaMode: {
      type: Boolean,
      default: false,
    },
    codeType: {
      type: String,
      default: '4',
    },
    code: {
      type: String,
      default: '',
    },
  },
  components: {
    CodeSearch,
  },
  data() {
    return {
      buyDisabled: false,
      title: {
        '1': {
          price: '买入价格',
          abuser: '可用资金',
          number: '买入数量',
          button: '买入下单',
        },
        '2': {
          price: '卖出价格',
          number: '卖出数量',
          button: '卖出下单',
        }
      },
      ruleForm: {
        name: '',
        wayTrade: '',
        resource: 0,
        price: 0,
        usable: 0,
        number: 0,
      },
      fid: '',
      allNumber: 0,
      radio: '',
      rules: {
        name: [
          { required: true, message: '请输入证券代码', trigger: 'change' },
        ],
        wayTrade: [
          { required: true, message: '请选择报价方式', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择开平', trigger: 'change' }
        ],
        price: [
          { required: true, message: '请输入买入价格', trigger: ['blur','change'] },
        ],
        usable: [
          { required: true, message: '请输入可用资金', trigger: 'blur' },
        ],
        number: [
          { required: true, message: '请输入最大可买', trigger: 'blur' },
        ],
      },
      // 大赛
      codeSList: []
    };
  },
  mounted() {
    // this.getPath()
  },
  watch: {
    price(val) {
      this.ruleForm.price = parseFloat(val);
    },
    codeList(val) {
      if (val.length !== 0 && !this.megaMode) {
        this.ruleForm.name = val[0].code
        this.fid = val[0].fid
      }
    },
    code(val) {
      this.ruleForm.name = val
    },
  },
  methods: {
    // getPath(){
    //   this.getMoneys()
    // },
    blurNumber(val) {
      let num = parseInt(val.target.value)
      let minNum = this.dealCodeSearch()
      this.ruleForm.number = num - num % minNum
    },

    dealCodeSearch() {
      let num = 100
      let codeList = this.codeList
      for (let j in codeList) {
        if (this.ruleForm.name === codeList[j].fgsid) {
          num = codeList[j].vt
        }
      }
      return num;
    },

    codeChange(val) {
      this.ruleForm.name = val
      if (!this.megaMode) {
        this.fid = this.codeList.filter(v => { if (v.fgsid === val) return v.fid })[0].fid
        this.$emit('codeChange', val)
      }
    },

    getMoneys() {
      let num = this.$route.params.id === (this.megaMode ? '3' : '3') ? 10 : 0
      findFunds({
        acid: JSON.parse(sessionStorage.getItem('acid')),
        // acid: JSON.parse(sessionStorage.getItem('classInfo')).acid,
        exchange: num
      }).then(res => {
        if (res.data.code === '200') {
          this.ruleForm.usable = res.data.data.funds[0] ? res.data.data.funds[0].freeAmount : 0
        }
      })
    },

    submitForm(formName, num) {
      let timeStart = checkAuditTime('15:05', '15:30')
      if (timeStart === 2) return ElMessage.warning('请在盘后交易时间内下单（15：05-15：30）')
      // let typeBuy = this.type === 1 ? 0: 1
      if (this.ruleForm.price === 0) return ElMessage.warning(num === 0 ? '买入' + '价格不能为0' : '卖出' + '价格不能为0')
      if (this.ruleForm.number === 0) return ElMessage.warning(num === 0 ? '买入' + '数量不能为0' : '卖出' + '数量不能为0')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          /*
          * tposition 开仓  0 ，平仓方向 1
          * direction 买 0 卖 1 方向
          * 股票,债券 买  tp 0 dir 0
          * 股，债 卖  tp 1 dir 1
          * 期货 买开 tp  0 dir 0
          *    买平  tp 1  dir 0
          *    卖开   tp 0 dir 1
           *    卖平 tp 1 dir 1
          * */
          this.buyDisabled = true;
          let parame = {
            acid: JSON.parse(sessionStorage.getItem('acid')),
            // accountId: JSON.parse(sessionStorage.getItem('classInfo')).acid,
            position: num,
            internalsid: this.fid,
            direction: num,
            orderPrice: this.ruleForm.price,
            orderVol: this.ruleForm.number,
            code:ruleForm.name
          }
          placeAnOrder(parame).then(res => {
            if (res.data.code === '200') {
              this.buyDisabled = false;
              ElMessage.success(res.data.msg)
              this.resetForm('ruleForm')
            } else {
              this.buyDisabled = false;
              ElMessage.error(res.data.msg)
            }
          })
        } else {
          return false;
        }
      });
    },
    // 时间区间


    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style lang="less">
.trade {
  .trade-item-right {
    width: 50%;
    max-width: 300px;
    min-width: 120px;
  }
}
</style>
